<template>
<div class="deposit-container">
    <p class="title">
        واریز {{$coinLabel[$route.params.coin]}}
    </p>
    <div class="info-container">
        <div class="coin-info">
            <div>
                <p class="label">
                    نوع کوین
                </p>
                <div class="selected-container" @click="drop = !drop">
                    <img class="list-img" :src="'/coins/'+ $route.params.coin + '.png'">
                    <span> {{$coinLabel[$route.params.coin]}} </span>
                    <img class="dropIcon" src="@/assets/dropIcon.svg" alt="">
                    <div class="drop-down" v-if="drop">
                        <div @click="selectWallet(wallet)" v-for="(wallet,index) in wallets" :key="index">
                            <img class="list-img" :src="'/coins/'+ wallet.relatedCoin + '.png'">
                            <span> {{$coinLabel[wallet.relatedCoin]}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="label">
                    آدرس کیف پول
                </p>
                <div @click="copy" class="selected-conteiner">
                    <img v-if="showQr" class="qrcode" :src="'data:image/png;base64,'+qrCode" >
                    <input class="address-input" type="text" v-model="address" disabled>
                    <inline-svg @mouseover="showQr=true" @mouseleave="showQr=false" :src="require('../../../assets/icons/qrCode.svg')" />
                </div>
            </div>
            <div>
                <p class="label">
                    شناسه واریز
                </p>
                <div class="deposite-check">
                    <input class="address-input" type="text" v-model="depositeCode">
                </div>
            </div>
            <button @click.prevent="checkDeposite" :disabled="!depositeCode" class="normal-btn">
                بررسی واریز
            </button>
        </div>
        <div class="guide-container">
            <div>
                راهنمای واریز
            </div>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span>
                    ارسال کوین غیر از {{$coinUnit[$route.params.coin]}} به این آدرس ممکن است منجر به از دست دادن آن برای همیشه شود.
                </span>
            </div>
        </div>
    </div>
    <div class="history-container">
        <p>
            تاریخچه واریز {{$coinLabel[$route.params.coin]}}
        </p>
        <div class="table-contaner">
            <span v-if="tableContents.length < 1" class="no-content">
                محتوایی جهت نمایش وجود ندارد
            </span>
            <perfect-scrollbar v-else @ps-y-reach-end="getDeposite">
            <table>
                <tr class="tr-head">
                    <td class="date">
                        زمان
                    </td>
                    <td>
                        رمزارز
                    </td>
                    <td>
                        مقدار درخواستی
                    </td>
                    <td class="address">
                        کارمزد شبکه
                    </td>
                    <td class="destination">
                        آدرس مقصد
                    </td>
                    <td>
                        وضعیت
                    </td>
                    <td class="trnasactionId">
                        شناسه تراکنش
                    </td>
                </tr>
                <tr class="tr-body" v-for="(item,index) in tableContents" :key="index">
                    <td class="date">
                        ۰۰/۲/۲ ۱۲:۲۳
                    </td>
                    <td>
                        بیت کوین
                    </td>
                    <td>
                        0.004
                    </td>
                    <td class="address">
                        5%
                    </td>
                    <td class="destination">
                        gwerfuygerf876876
                    </td>
                    <td class="success">
                        موفق
                    </td>
                    <td class="trnasactionId">
                        مشاهده جزییات
                    </td>
                </tr>
            </table>
            </perfect-scrollbar>
        </div>
    </div>
</div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
export default {
    name: 'CoinDeposit',
    component:{
        PerfectScrollbar
    },
    data() {
        return {
            depositeCode:'',
            address: '',
            qrCode:'',
            drop: false,
            wallets:[],
            selectCoin:[],
            showQr:false,
            tableContents:[],
            page:0,
            lastPage:1,
        }
    },
    methods: {
            copy(){
                navigator.clipboard.writeText(this.address)
                this.$error('','آدرس کپی شد','info','تایید')
            },
            async getDeposite(){
                if (this.page < this.lastPage) {
                    this.page++ ;
                    const res = await this.$axios.get('/wallets/deposit', {
                        params: {
                            size:10,
                            page: this.page,
                            type:'dep'
                        }
                    })
                    if (res) {
                        this.lastPage = res.totalPages
                        let content = res.content.filter(a => a.relatedCoin == this.$route.params.coin)
                        this.tableContents = [...this.tableContents, ...content] //for scroll loading
                        // this.tableContents = res.content //for pagination
                    }
                }
            },
            async getWallets() {
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses')
                    let entireRes = Object.entries(res)
                    this.sortWallets(entireRes);
            },
            sortWallets(e){
                const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN'];
                e.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = e.map(a => {
                        let relatedCoin = a[0]
                        if(a.relatedCoin) {
                            a=a[1]
                        }else{
                            a=a[1]
                            a.relatedCoin=relatedCoin
                        }
                        return a
                        })
                    this.wallets=this.wallets.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                    this.wallets.sort((a,b) => b.credit - a.credit)
            },
            async checkDeposite(){
                this.state.loading=true
                const res = await this.$axios.get('/wallets/check-confirmed-transaction',{
                    params:{
                        relatedCoin:this.$route.params.coin,
                        tokenType:this.selectCoin.tokenType
                    }
                })
                res ? this.$error('پیگیری واریز','مقدار واریز شناسایی نشد','info','تایید','تایید') : ''
                
            },
            async getData() {
                const res = await this.$axios.get('/coins')
                this.selectCoin = res.content.filter(a => a.coin==this.$route.params.coin)[0]
                this.getSelectedWallet()
            },
            async getSelectedWallet(){
                this.state.loading=true
                const [res,err] = await this.$http.get(`/wallets/customer/wallet-address?walletType=P2P&tokenType=${this.selectCoin.tokenType !=='UNKNOWN' ? this.selectCoin.tokenType : ''}&relatedCoin=${this.selectCoin.coin}`)
                    if(res){
                        this.address=res.baseDTO.address
                        this.qrCode=res.baseDTO.qrCode
                    }
                    if(err){
                        this.$router.push({name:'Wallet'})
                    }
            },
            selectWallet(wallet){
                this.$router.push('/dashboard/deposit-coin/'+wallet.relatedCoin)
                this.getData();

            }
    },
    mounted() {
        this.getDeposite();
        this.getWallets();
        this.getData();
    },


    created:function(){
        window.addEventListener("click" , event => {
            if(!event.target.closest('.selected-container')){
                this.drop=false;
            }
        })
    }

}
</script>

<style lang="scss" scoped>
@import '../../../Styles/Wallet.scss';
.qrcode{
    position: absolute;
    width: 124px;
    height: 124px;
    top: 20px;
    left: -10px;
}
.coin-info{
    position: relative;
}

.limit-container{
    cursor: text !important; 
}
.drop-down{
    z-index: 3;
    max-height: 250px;
    overflow-y: scroll;
}
.selected-conteiner{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #ECEEEF;
    border-radius: 8px;
    align-items: center;
    input{
        cursor: pointer;
        flex: 9;
    }
    svg{
        cursor: pointer;
        transition: 300ms;
        flex: 1;
        width: 20px;
        height: 20px;
        fill: var(--grey);
        &:hover{
            fill: var(--primary-color) !important;
        }
    }
}
</style>
